<template>
  <v-app>
    <v-container>
      <v-card>
        <v-card-title class="pb-0">
          Финансы
          <v-btn class="ml-2" @click="$refs.edit.open({id:0, income: 0})">+ Расход</v-btn>
          <v-btn class="ml-2" @click="$refs.edit.open({id:0, income: 1})">+ Доход</v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-chip color="#fff" label>
                  <v-icon size="20" style="margin-right: 5px" color="green">mdi-cash-100</v-icon>
                  {{ $root.printCost(balance.cash) }}
                </v-chip>
              </div>
            </template>
            <span>Наличные</span>
          </v-tooltip>
          <v-btn @click="$refs.exchange.open(0)" icon>
            <v-icon>mdi-arrow-left-right</v-icon>
          </v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-chip color="#fff" class="mr-2" label>
                  <v-icon size="20" style="margin-right: 5px" color="green">mdi-credit-card-outline</v-icon>
                  {{ $root.printCost(balance.card) }}
                </v-chip>
              </div>
            </template>
            <span>На счете</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-chip color="#fff" class="mr-2" label>
                  Всего
                  {{ $root.printCost(balance.total) }}
                </v-chip>
              </div>
            </template>
            <span>Всего</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-chip style="color: #3399ff" color="#fff" class="mr-2" label>
                  {{ $root.printCost(balance.todayCard) }}
                </v-chip>
              </div>
            </template>
            <span>Поступит сегодня на счёт</span>
          </v-tooltip>
        </v-card-title>
        <v-card-actions>
          <v-row>
            <v-col class="col-auto col-md-2 pt-0 pt-md-2">
              <label>Период</label>
              <v-menu
                  ref="menu"
                  transition="scale-transition"

                  :close-on-content-click="false"
                  bottom
                  nudge-bottom="35"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      outlined
                      :value="$root.outputRange(filters.date)"
                      readonly
                      v-bind="attrs"
                      style="width: 100%"
                      v-on="on"
                  />
                  <v-btn
                      icon
                      @click.stop="filters.date =[]"
                      color="grey"
                      :style="filters.date.length ? '' : 'display: none'"
                      style="position: absolute;right: 5px;top: 28px;z-index: 100;"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                    v-model="filters.date"
                    no-title
                    scrollable
                    @change="reloadTable(true)"
                    range
                    first-day-of-week="1"
                    locale="ru"
                />
              </v-menu>
            </v-col>
            <v-col class="col-auto col-md-2 pt-0 pt-md-2">
              <label>Основание</label>
              <v-select
                  @change="reloadTable(true)"
                  v-model="filters.types"
                  item-text="name"
                  multiple
                  item-value="value"
                  :items="$root.selectValues(types.income).concat($root.selectValues(types.cons))"
                  outlined
              ></v-select>
            </v-col>
            <v-col class="col-auto col-md-2 pt-0 pt-md-2">
              <label>Нал/безнал</label>
              <v-select
                  @change="reloadTable(true)"
                  v-model="filters.paymentType"
                  item-text="name"
                  item-value="value"
                  :items="[{name:'Всё', value: '0'}, {name:'Безнал', value: '1'}, {name:'Наличные', value: '2'}]"
                  outlined
              ></v-select>
            </v-col>
            <v-col class="col-auto col-md-2 pt-0 pt-md-2">
              <label>Доходы/Расходы</label>
              <v-select
                  @change="reloadTable(true)"
                  v-model="filters.income"
                  item-text="name"
                  item-value="value"
                  :items="[{name:'Всё', value: ''}, {name:'Доходы', value: '1'}, {name:'Расходы', value: '0'}]"
                  outlined
              ></v-select>
            </v-col>
            <v-col class="col-auto col-md-2 pt-0 pt-md-2">
              <label>По какой дате</label>
              <v-select
                  @change="reloadTable(true)"
                  v-model="filters.byItemDate"
                  item-text="name"
                  item-value="value"
                  :items="[{name:'По дате прихода/расхода', value: '0'}, {name:'Помесячно', value: '1'}]"
                  outlined
              ></v-select>
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-text class="pt-0">
          <v-simple-table style="width: fit-content;">
            <thead>
            <tr>
              <th>Основание</th>
              <th style="text-align: right">Всего</th>
              <th style="text-align: right">Безнал/Нал</th>
            </tr>
            <tr>
              <th style="font-weight: 800;font-size: 16px">Итого</th>
              <th style="text-align: right">
                <SumChip :value="info.total"></SumChip>
              </th>
              <th>{{$root.printCost(info.card)}} / {{$root.printCost(info.cash)}}
              </th>
            </tr>
            <tr>
              <th style="font-weight: 800;font-size: 16px">Итого доход</th>
              <th style="text-align: right">
                <SumChip :value="info.cashPlus+info.cardPlus"></SumChip>
              </th>
              <th>{{$root.printCost(info.cardPlus)}} / {{$root.printCost(info.cashPlus)}}
              </th>
            </tr>
            <tr>
              <th style="font-weight: 800;font-size: 16px">Итого расход</th>
              <th style="text-align: right">
                <SumChip :value="info.cashMinus+info.cardMinus"></SumChip>
              </th>
              <th>{{$root.printCost(info.cardMinus)}} / {{$root.printCost(info.cashMinus)}}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr style="cursor: pointer" v-for="row in info.byType" v-if="row.title && (parseFloat(row.cash) ||parseFloat(row.card))"
                :style="'background:'+(localFilter.type==row.type && localFilter.paymentType==0 ? '#aef2f6':'')"
                @click="setLocalFilter(row.type, 0)"
            >
              <td>{{ row.title }}</td>
              <td style="text-align: right">
                <SumChip :value="row.total"></SumChip>
              </td>
              <td> {{$root.printCost(row.card)}} / {{$root.printCost(row.cash)}}
              </td>
            </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
        <v-card-text class="pt-0">
          <v-data-table
              hover
              :items="rows.filter((item)=>(!localFilter.type || localFilter.type==item.type) && (!localFilter.paymentType || localFilter.paymentType==item.paymentType))"
              :headers="headers"
              item-key="id"
              class="cursor-pointer items"
              :loading="loading"
              :footer-props="{'items-per-page-options':[30]}"
              no-data-text="Строк нет"
              loading-text="Загрузка..."
              mobile-breakpoint="0">
            <template v-slot:item="{item}">
              <tr @click="$refs.edit.open(item)">
                <td>{{ $root.dateToRus(item.date) }}</td>
                <td :style="'color: '+(parseFloat(item.sum)<0 ? 'red' : 'green')">{{ item.sum }}</td>
                <td>{{ ['', 'Безнал', 'Нал'][parseInt(item.paymentType)] }}</td>
                <td>{{ types[parseFloat(item.sum) < 0 ? 'cons' : 'income'][parseInt(item.type)] }}</td>
                <td>
                  {{ item.comment }}
                </td>
                <td>{{ $root.managerName(item.user) }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
    <Edit ref="edit"/>
    <Exchange ref="exchange"></Exchange>
  </v-app>
</template>
<style>
.balance_info .v-list-item__content {
  display: block;
}

.balance_info .v-list-item__title {
  display: inline-block
}

.balance_info .v-list-item__subtitle {
  display: inline-block;
  margin-bottom: -3px
}
</style>
<script>
import Edit from "./Edit";
import SumChip from "@/components/SumChip";
import Exchange from "./Exchange";

export default {
  name: "Table",
  components: {
    Exchange,
    Edit, SumChip
  },
  data() {
    return {
      rows: [],
      types: [],
      info: {},
      loading: true,
      balance: {},
      localFilter: {
        paymentType: 0,
        type: 0,
      },
      filters: {
        date: [this.$moment().startOf('month').format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')],
        paymentType: '0',
        byItemDate: '0',
        income: '',
      },
      headers: [
        {text: 'Дата', value: 'date'},
        {text: 'Сумма', value: 'sum'},
        {text: 'Способ оплаты', value: 'paymentType'},
        {text: 'Тип', value: 'type'},
        {text: 'Коммент', value: 'comment'},
        {text: 'Менеджер', value: 'user'},
      ],
    }
  },
  methods: {
    setLocalFilter(type, paymentType) {
      document.querySelector('.c-body').scrollTop = this.$el.querySelector('.v-data-table.items').offsetTop
      if (this.localFilter.type === type && this.localFilter.paymentType === paymentType) {
        type = 0;
        paymentType = 0;
      }
      this.localFilter.type = type;
      this.localFilter.paymentType = paymentType;
    },
    setRows(rows) {
      this.info = {
        cardPlus: 0,
        cardMinus: 0,
        cashPlus: 0,
        cashMinus: 0,
      };
      this.info.byType = {};
      this.info.total = 0;
      this.info.cash = 0;
      this.info.card = 0;
      let byType = {};
      for (let index in this.types.income) {
        byType['_' + index] = {
          type: index,
          income: 1,
          total: 0,
          cash: 0,
          card: 0,
          title: this.types.income[index],
        }
      }
      for (let index in this.types.cons) {
        byType['_' + index] = {
          type: index,
          income: 0,
          total: 0,
          cash: 0,
          card: 0,
          title: this.types.cons[index]
        }
      }
      rows.forEach(row => {
        let sum = this.$root.round(row.sum);
        this.info.total += sum;
        byType['_' + parseInt(row.type)].total += sum;
        if (parseInt(row.paymentType) === 1) {
          byType['_' + parseInt(row.type)].card += sum;
          this.info.card += sum;
          if (sum >= 0) this.info.cardPlus += sum;
          else this.info.cardMinus += sum;
        } else {
          byType['_' + parseInt(row.type)].cash += sum;
          this.info.cash += sum;
          if (sum >= 0) {
            this.info.cashPlus += sum;
            // console.log(this.info.cashPlus, sum, row.type);
          }
          else this.info.cashMinus += sum;
        }
      })
      this.info.byType = Object.values(byType).sort((a, b) => {
        if (a.income !== b.income) return b.income - a.income;
        return Math.abs(b.total) - Math.abs(a.total);
      })
      console.log(this.info.byType)
      this.rows = rows;
    },
    reloadTable(clearTable = true) {
      if (clearTable === true) {
        this.loading = true;
        this.setRows([]);
        console.log('save filters', {...this.filters});
        this.$root.saveData('balanceRegions', 'filters', this.filters);
      }
      this.$store.state.server.request('balanceRegions/get', this.filters, (data) => {
        this.types = data.types;
        this.balance = data.balance;
        this.setRows(data.response);
        this.loading = false;
        if (!clearTable) setTimeout(() => {
          if (this.$router.currentRoute.path === '/regions/balance') this.reloadTable(false)
        }, 5000)
      }, () => {
        if (!clearTable) setTimeout(() => {
          if (this.$router.currentRoute.path === '/regions/balance') this.reloadTable(false)
        }, 5000)
      });
    },
    loadFilters() {
      let saved = this.$root.getData('balanceRegions', 'filters') || {};
      for (let field in this.filters) {
        if (saved.hasOwnProperty(field)) this.filters[field] = saved[field];
      }
    },
  },
  mounted() {
    this.loadFilters();
    this.reloadTable(false)
    this.$eventBus.$on('balanceRegionsSaved', () => {
      this.reloadTable()
    });
  },
  destroyed() {
    this.$eventBus.$off('balanceRegionsSaved');
  }
}
</script>