<template>
  <v-dialog max-width="500" data-app v-model="opened" @input="onToggle()">
    <v-card>
      <v-card-title></v-card-title>
      <v-icon class="btn-close-modal" @click="opened=false" size="36">mdi-close</v-icon>
      <v-card-text>
        <v-row class="row-d-block">
          <v-btn class="mr-2" :class="!item.type?'' : 'v-btn-transp'" @click="item.type=0;$forceUpdate();">
            Вывод наличных со счёта
          </v-btn>
          <v-btn :class="item.type?'' : 'v-btn-transp'" @click="item.type=1;$forceUpdate();">Наличные на счёт</v-btn>
        </v-row>
        <v-row class="row-d-block">
          <v-subheader>Дата</v-subheader>
          <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  outlined
                  :value="$root.dateToRus(item.date)"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              />
            </template>
            <v-date-picker
                v-model="item.date"
                no-title
                scrollable
                first-day-of-week="1"
                locale="ru"
                :reactive="true"
                @input="menu = false"
            />
          </v-menu>
        </v-row>
        <v-row class="row-d-block">
          <v-subheader>Сумма</v-subheader>
          <v-text-field
              v-model="item.sum"
              outlined
              type="number"/>
        </v-row>
        <v-row class="row-d-block">
          <v-subheader>Комиссия в процентах</v-subheader>
          <v-text-field
              v-model="item.commission"
              outlined
              placeholder="1%"
              type="number"/>
        </v-row>
        <span
            v-if="item.commission">Со счёта списалось с учетом комиссии: {{ $root.printCost(item.sum * (1 + item.commission / 100)) }}</span>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="save" :loading="loading">Перевести</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "Exchange",
  data() {
    let today = this.$moment().format('YYYY-MM-DD');
    return {
      opened: false,
      menu: false,
      item_id: 0,
      deleteItem: 0,
      today,
      loading: false,
      loadingRemove: false,
      item: {commission: '', sum: '', type: 0, date: today}
    }
  },
  methods: {
    open(type) {
      this.opened = true;
      this.onToggle();
      this.item.type = type;
    },
    onToggle() {
      this.item = {date: this.today};
    },
    close() {
      this.opened = false;
      this.onToggle();
    },
    save() {
      this.loading = true;
      this.item.sum = Math.abs(this.item.sum);
      if (!this.item.income) this.item.sum = this.$root.round(-this.item.sum);
      if (!parseInt(this.item.paymentType)) this.item.paymentType = 2;
      this.$store.state.server.request('balanceRegions/exchange', this.item, (data) => {
        this.loading = false;
        this.$root.notify('Сохранено', 'success')
        this.$eventBus.$emit('balanceRegionsSaved')
        this.close();
      }, (data) => {
        this.$root.notify(data && data.error ? data.error : 'Ошибка сохранения', 'error');
        this.loading = false;
      });
    },
  },
}
</script>